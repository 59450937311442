import React from 'react'
import PageContent from 'app/components/PageContent'
import Instagram from './Instagram'
import Pinterest from './Pinterest'
import styles from './styles.module.css'

const Social = () => {
  return (
    <PageContent>
      <div className={styles.grid}>
        <Instagram />
        <Pinterest />
      </div>
    </PageContent>
  )
}

export default Social
