import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { useBreakpoint } from 'app/hooks'
import { ReactComponent as CloseIcon } from './close-icon.svg'
import styles from './styles.module.css'

const Flyout = ({ isOpen, children, title, onClose, font = 'nunito' }) => {
  const [animateClose, setAnimateClose] = useState(false)
  const isMobile = useBreakpoint('md')

  useEffect(() => {
    if (isOpen && !animateClose) {
      setAnimateClose(true)
    }

    if (!isOpen && animateClose) {
      setAnimateClose(false)
    }
  }, [isOpen, animateClose])

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.flyout, {
        [styles.open]: isOpen,
        [styles.animateClose]: animateClose,
        [styles.mobile]: isMobile
      })}
    >
      <div className={classNames(styles.flyoutHeader, styles[font])}>
        <span>{title}</span>
        {onClose && <CloseIcon className={styles.closeIcon} onClick={onClose} />}
      </div>
      <div className={styles.flyoutContent}>{children}</div>
    </div>,
    document.body
  )
}

export default Flyout
