import React from 'react'
import styles from './styles.module.css'

const Post = ({ media_type, media_url, permalink }) =>
  !media_type || media_type === 'IMAGE' ? (
    <div className={styles.post}>
      <a className={styles.link} href={permalink} target="_blank" rel="noreferrer">
        <img className={styles.image} src={media_url} />
      </a>
    </div>
  ) : null

export default Post
