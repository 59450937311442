import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
const firebaseui = require('firebaseui')

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const authorizedUIDs = [process.env.REACT_APP_TH_UID, process.env.REACT_APP_EB_UID]

firebase.initializeApp(firebaseConfig)

export const onAuthStateChanged = callback => {
  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      callback({
        loggedIn: true,
        authorized: authorizedUIDs.includes(user.uid),
        displayName: user.displayName,
        email: user.email,
        photoUrl: user.photoURL,
        uid: user.uid
      })
    } else {
      callback({
        loggedIn: false,
        authorized: false,
        displayName: null,
        email: null,
        photoUrl: null,
        uid: null
      })
    }
  })
}

export const logout = () => {
  firebase.auth().signOut()
}

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: authResult => {
      return authorizedUIDs.includes(authResult?.user?.uid)
    }
  },
  signInFlow: 'popup',
  signInSuccessUrl: '/admin',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
}

const ui = new firebaseui.auth.AuthUI(firebase.auth())

export const startFirebaseAuth = elementId => {
  ui.start(elementId, uiConfig)
}

export { firebase }
