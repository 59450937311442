import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Tray from 'app/components/Tray'
import useReactRouter from 'use-react-router'
import Contact from './Contact'
import styles from './styles.module.css'

const sections = [
  { slug: '', title: 'Home' },
  { slug: 'portfolio', title: 'Portfolio' },
  { slug: 'social', title: 'Social' }
]

const MobileHeader = () => {
  const { history, location } = useReactRouter()
  const [path, setPath] = useState(location?.pathname?.replace('/', ''))
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)

  const setSelection = slug => {
    setPath(slug)
    setTimeout(() => setIsFlyoutOpen(false), 200)
    setTimeout(() => history.replace({ ...location, pathname: slug || '/' }), 500)
  }

  const [isContactOpen, setIsContactOpen] = useState(false)

  useEffect(() => {
    setIsContactOpen(false)
  }, [isFlyoutOpen])

  return (
    <>
      <Tray
        isOpen={isFlyoutOpen}
        onClose={() => setIsFlyoutOpen(false)}
        onOpen={() => setIsFlyoutOpen(true)}
      >
        {isContactOpen ? (
          <Contact onClose={() => setIsContactOpen(false)} />
        ) : (
          <>
            <div className={styles.navLinks}>
              {sections.map(({ slug, title }) => {
                return (
                  <div
                    className={classNames(styles.navLink, {
                      [styles.active]: path === slug
                    })}
                    onClick={() => setSelection(slug)}
                    key={`mobile-nav-${slug}`}
                  >
                    {title}
                  </div>
                )
              })}
              <div className={styles.navLink} onClick={() => setIsContactOpen(true)}>
                Contact
              </div>
            </div>
            <div className={styles.siteFooter}>
              <span className={styles.copyright}>© 2021 Ellie Baer Design</span>
              <span className={styles.author}>
                Website by{' '}
                <a
                  href="https://www.linkedin.com/in/timharding31/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tim Harding
                </a>
              </span>
            </div>
          </>
        )}
      </Tray>
      <div className={styles.header}>
        <span
          className={styles.headerText}
          onClick={() => {
            history.push({ ...location, pathname: '/' })
            setPath('')
          }}
        >
          Ellie Baer
        </span>
      </div>
    </>
  )
}

export default MobileHeader
