import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { ReactComponent as Chevron } from './chevron.svg'
import styles from './styles.module.css'

const Tray = ({ isOpen, onOpen, onClose, children }) => {
  const [animateClose, setAnimateClose] = useState(false)

  useEffect(() => {
    if (isOpen && !animateClose) {
      setAnimateClose(true)
    }

    if (!isOpen && animateClose) {
      setAnimateClose(false)
    }
  }, [isOpen, animateClose])

  return (
    <div
      className={classNames(styles.tray, {
        [styles.open]: isOpen,
        [styles.animateClose]: animateClose
      })}
    >
      <div
        className={classNames(styles.bar, { [styles.open]: isOpen })}
        onClick={() => {
          if (isOpen) {
            onClose()
          } else {
            onOpen()
          }
        }}
      >
        <Chevron className={classNames(styles.chevron, { [styles.open]: isOpen })} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default Tray
