import React, { useState } from 'react'
import { useFirebaseImage } from '../../hooks'
import classNames from 'classnames'
import styles from './styles.module.css'

const Frame = ({ image, aspectRatio, title, description, interactive = true, isMobile }) => {
  const imageSrc = useFirebaseImage(image)
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)

  return imageSrc ? (
    <div
      className={styles.frame}
      onClick={() => interactive && setIsDescriptionVisible(!isDescriptionVisible)}
    >
      <div className={classNames(styles.imageContainer, styles[aspectRatio])}>
        <img className={styles.image} src={imageSrc} />
        <div
          className={classNames(styles.descriptionOverlay, {
            [styles.visible]: isDescriptionVisible
          })}
        >
          <div
            className={classNames(styles.description, {
              [styles.visible]: isDescriptionVisible,
              [styles.mobile]: isMobile
            })}
          >
            {description}
          </div>
        </div>
      </div>
      <div className={styles.innerBorder} />
      <div className={styles.caption}>{title}</div>
    </div>
  ) : null
}

export default Frame
