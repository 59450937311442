import React from 'react'
import PageContent from 'app/components/PageContent'
import styles from './styles.module.css'
import { useBreakpoint } from 'app/hooks'
const image = require('../../../assets/line-drawing.svg')

const DesktopHome = () => (
  <div className={styles.grid}>
    <div className={styles.image}>
      <img src={image.default} className={styles.img} />
    </div>
    <div className={styles.bio}>
      <p className={styles.heading}>
        Ellie Baer is an interior designer based in San Francisco, CA
      </p>
      <p className={styles.paragraph}>
        She is inspired by her travels across the world, and combining clean lines and fresh
        palettes with antique and unique finds for a space that feels both beautiful and livable.
        Ellie works closely to understand the needs and desires of her clients to create evergreen,
        timeless rooms with pieces that can be held on to forever.
      </p>
      <p className={styles.paragraph}>
        Ellie has a unique talent in working with clients to achieve their goals based on their
        lifestyle, timeline, budget, and needs, and creates spaces and formulas that allow her
        clients to grow in their spaces so they don’t feel static.
      </p>
      <p className={styles.paragraph}>
        Ellie has worked with clients across the United States, and currently designs in conjunction
        with{' '}
        <a
          href="https://app2.spoak.com/u/elliebaer/portfolio?t=thingology"
          target="_blank"
          className={styles.link}
        >
          Spoak
        </a>
        , which allows her to illustrate spaces in a unique and personalized fashion. She conducts
        multiple meetings with clients to determine process, and conducts intensive research to
        ensure each client emerges with the space of their dreams.
      </p>
    </div>
  </div>
)

const MobileHome = () => (
  <div className={styles.bioMobile}>
    <p className={styles.heading}>Ellie Baer is an interior designer based in San Francisco, CA</p>
    <p className={styles.paragraph}>
      She is inspired by her travels across the world, and combining clean lines and fresh palettes
      with antique and unique finds for a space that feels both beautiful and livable. Ellie works
      closely to understand the needs and desires of her clients to create evergreen, timeless rooms
      with pieces that can be held on to forever.
    </p>
    <p className={styles.paragraph}>
      Ellie has a unique talent in working with clients to achieve their goals based on their
      lifestyle, timeline, budget, and needs, and creates spaces and formulas that allow her clients
      to grow in their spaces so they don’t feel static.
    </p>
    <p className={styles.paragraph}>
      Ellie has worked with clients across the United States, and currently designs in conjunction
      with{' '}
      <a
        href="https://app2.spoak.com/u/elliebaer/portfolio?t=thingology"
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        Spoak
      </a>
      , which allows her to illustrate spaces in a unique and personalized fashion. She conducts
      multiple meetings with clients to determine process, and conducts intensive research to ensure
      each client emerges with the space of their dreams.
    </p>
  </div>
)

const Home = () => {
  const isMobile = useBreakpoint('md')
  return <PageContent>{isMobile ? <MobileHome /> : <DesktopHome />}</PageContent>
}

export default Home
