import React, { useContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from 'app/firebase'

const defaultUser = {
  loggedIn: false,
  authorized: false,
  displayName: null,
  email: null,
  photoUrl: null,
  uid: null
}

const IdentityContext = React.createContext(defaultUser)

const IdentityContextProvider = ({ children }) => {
  const [user, setUser] = useState(defaultUser)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(setUser)
    return () => unsubscribe()
  }, [])

  return <IdentityContext.Provider value={{ user, setUser }}>{children}</IdentityContext.Provider>
}

const useIdentityContext = () => {
  const identityContext = useContext(IdentityContext)

  return identityContext
}

export default IdentityContextProvider

export { IdentityContext, useIdentityContext }
