import React from 'react'
import classNames from 'classnames'
import { useBreakpoint } from 'app/hooks'
import { Carousel } from 'react-responsive-carousel'
import styles from './styles.module.css'

const Testimonials = () => {
  const isMobile = useBreakpoint('md')
  return (
    <Carousel
      className={styles.carousel}
      showIndicators={false}
      interval={30000}
      infiniteLoop={true}
      showThumbs={false}
      autoFocus={true}
      autoPlay={true}
      stopOnHover={false}
      showArrows={false}
      width={'96vw'}
      showStatus={false}
    >
      <div className={classNames(styles.testimonial, { [styles.mobile]: isMobile })}>
        <p className={styles.paragraph}>
          I can't say enough good things about Ellie's work and she has far and beyond exceeded my
          expectations. I hope you all will be able to promote her work across the platform as I
          think she has a unique yet relatable approach to design. She effortlessly took my jumbled
          thoughts and translated them into a design that{' '}
          <strong className={styles.strong}>
            I can't wait to show off to my friends and family.
          </strong>
        </p>
        <p className={styles.paragraph}>
          You can count me as a <strong className={styles.strong}>very happy customer</strong> that
          has already shared my experience with Spoak and Ellie with my neighbor who came over last
          night!
        </p>
        <span className={styles.author}>
          — Erin, <span>Jersey City</span>
        </span>
      </div>
      <div className={classNames(styles.testimonial, { [styles.mobile]: isMobile })}>
        <p className={styles.paragraph}>
          <strong className={styles.strong}>I 100% recommend</strong> Ellie's services to anyone
          looking for interior design help. Decorating our small Chelsea apartment was no easy task.
          Ellie was able to erase the stress from the process and was extremely easy to work with. I
          really appreciated how she provided numerous options that fell within our budget and
          pulled in items from various retailers, making sure to support small businesses while
          doing so.
        </p>
        <p className={styles.paragraph}>
          Ellie's style is <strong className={styles.strong}>unique and elevated</strong>, while
          customized towards the client. I guarantee you will love your space and get tons of
          compliments after working with Ellie!
        </p>
        <span className={styles.author}>
          — Kaila, <span>New York City</span>
        </span>
      </div>
      <div className={classNames(styles.testimonial, { [styles.mobile]: isMobile })}>
        <p className={styles.paragraph}>
          Ellie was a delight to work with. She was able to give me a range of options within my
          budget while also suggesting areas to save and splurge should I be so inclined. It was
          wonderful work with someone with a clear passion for interior design who keeps her clients
          needs in the forefront.{' '}
          <strong className={styles.strong}>
            Ellie helped me transform my cookie cutter space into one that reflected my personal
            style.
          </strong>
        </p>
        <p className={styles.paragraph}>
          I love my functional yet fun apartment and it's all thanks to her.
        </p>
        <span className={styles.author}>
          — Emily, <span>Menlo Park</span>
        </span>
      </div>
      <div className={classNames(styles.testimonial, { [styles.mobile]: isMobile })}>
        <p className={styles.paragraph}>
          Working with Ellie is like hanging out with a friend who happens to want to help you think
          through every idea you've had for your home design. She brings a{' '}
          <strong className={styles.strong}>fresh perspective</strong> and always helps me break out
          of that mental design box you get when you've looked at something FAR too much. She's also
          incredibly practical, particularly when it comes to sourcing products, budgets, and using
          your living space to the max.
        </p>
        <p className={styles.paragraph}>
          Overall, I can't recommend her enough.{' '}
          <strong className={styles.strong}>Get yourself an Ellie immediately.</strong>
        </p>
        <span className={styles.author}>
          — Rachel, <span>San Francisco</span>
        </span>
      </div>
    </Carousel>
  )
}

export default Testimonials
