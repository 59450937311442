import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as PlusIcon } from './plus-icon.svg'
import { ReactComponent as CloseIcon } from './close-icon.svg'
import styles from './styles.module.css'

export const ModalTrigger = ({ onOpen }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={styles.new}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onOpen}
    >
      <PlusIcon className={styles.icon} />
      {isHovered && <div className={styles.tooltip}>New Project</div>}
    </div>
  )
}

export const ModalHeader = ({ onClose, title, children }) => {
  return (
    <>
      <div className={styles.modalHeader}>{title}</div>
      <div className={styles.closeIcon} onClick={onClose}>
        <CloseIcon />
      </div>
    </>
  )
}

export const ModalContent = ({ children }) => {
  return <div className={styles.modalContent}>{children}</div>
}

export const ModalFooter = ({ children }) => {
  return <div className={styles.modalFooter}>{children}</div>
}

const Modal = ({ onClose, children }) => {
  const overlayRef = useRef()
  const modalRef = useRef()

  const handleClickOutside = e => {
    if (overlayRef.current?.contains(e.target) && !modalRef.current?.contains(e.target)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.modalOverlay} ref={overlayRef}>
      <div className={styles.modal} ref={modalRef}>
        {children}
      </div>
    </div>
  )
}

export default Modal
