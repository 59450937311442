import Home from './Home'
import Portfolio from './Portfolio'
import Social from './Social'
import Admin from './Admin'

const routes = {
  homePage: {
    path: '/',
    exact: true,
    component: Home
  },
  portfolio: {
    path: '/portfolio',
    exact: false,
    component: Portfolio
  },
  social: {
    path: '/social',
    exact: false,
    component: Social
  },
  admin: {
    path: '/admin',
    exact: true,
    component: Admin
  }
}

export default routes
