import React, { useState, useContext, createContext } from 'react'
import Header from 'app/components/Header'

const HeaderContext = createContext()

const HeaderContextProvider = ({ children }) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState(true)

  return (
    <HeaderContext.Provider
      value={{
        isHeaderOpen,
        onCloseHeader: () => setIsHeaderOpen(false),
        onOpenHeader: () => setIsHeaderOpen(true)
      }}
    >
      <Header isDesktopHeaderOpen={isHeaderOpen} />
      {children}
    </HeaderContext.Provider>
  )
}

const useHeaderContext = () => {
  const headerContext = useContext(HeaderContext)

  return headerContext
}

export default HeaderContextProvider

export { HeaderContext, useHeaderContext }
