import React, { useState, useRef, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { send } from 'emailjs-com'
import Button from 'app/components/Button'
import styles from './styles.module.css'

const baseEmailParams = {
  from_name: '',
  reply_to: '',
  message: ''
}

const sendEmail = (params = baseEmailParams) => {
  send(
    process.env.REACT_APP_SERVICE_ID,
    process.env.REACT_APP_TEMPLATE_ID,
    params,
    process.env.REACT_APP_EMAIL_USER_ID
  )
}

const ContactModal = () => {
  const { history, location } = useReactRouter()
  const isOpen = location.search === '?contact'
  const onClose = () => {
    history.push({ ...location, search: undefined })
    setError('')
    setSuccess('')
  }

  const [emailParams, setEmailParams] = useState(baseEmailParams)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const onSend = e => {
    e.preventDefault()
    if (emailParams.from_name && emailParams.reply_to && emailParams.message) {
      sendEmail(emailParams)
      setEmailParams(baseEmailParams)
      setSuccess("Thanks for your message, I'll be in touch shortly!")
      setError('')
      setTimeout(onClose, 2000)
    } else {
      setSuccess('')
      setError('Please fill out all required fields')
    }
  }

  const onChangeInput = field => e => {
    e.preventDefault()
    setEmailParams({ ...emailParams, [field]: e.target.value })
  }

  const overlayRef = useRef()
  const modalRef = useRef()

  const handleClickOutside = e => {
    if (overlayRef.current?.contains(e.target) && !modalRef.current?.contains(e.target)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [location.pathname])

  return isOpen ? (
    <div className={styles.modalOverlay} ref={overlayRef}>
      <div className={styles.modal} ref={modalRef}>
        <form onSubmit={onSend} className={styles.form}>
          <div className={styles.title}>Send Ellie a message</div>
          <div className={styles.feedback}>
            {error && <span className={styles.error}>{error}</span>}
            {success && <span className={styles.success}>{success}</span>}
          </div>
          <div className={styles.formContent}>
            <div className={styles.row}>
              <input
                className={styles.input}
                value={emailParams.from_name}
                placeholder="Your name"
                onChange={onChangeInput('from_name')}
              />
              <input
                className={styles.input}
                value={emailParams.reply_to}
                placeholder="Your email"
                onChange={onChangeInput('reply_to')}
              />
            </div>
            <textarea
              className={styles.input}
              value={emailParams.message}
              placeholder="Message"
              onChange={onChangeInput('message')}
              style={{
                minHeight: '160px',
                resize: 'none'
              }}
            />
          </div>
          <div className={styles.formFooter}>
            <Button type="button" size="medium" onClick={onClose}>
              Close
            </Button>
            <Button type="submit" size="medium">
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  ) : null
}

export default ContactModal
