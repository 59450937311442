import { useState, useEffect } from 'react'
import { firebase } from 'app/firebase'
import { useQuery, useQueryClient } from 'react-query'
import throttle from 'lodash/throttle'

const breakPointMap = {
  sm: 320,
  md: 720,
  ml: 798,
  lg: 900,
  xlg: 1024
}

export const useBreakpoint = breakPoint => {
  const minWidth = breakPointMap[breakPoint]
  const [isWindowSmaller, setIsWindowSmaller] = useState(window.innerWidth <= minWidth)

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setIsWindowSmaller(window.innerWidth <= minWidth)
    }, 200)

    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return isWindowSmaller
}

const firebaseDatabseSubscribe = ({ path, callback, event = 'value' }) => {
  const ref = firebase.database().ref(path)
  const cb = snapshot => {
    callback(snapshot.val())
  }

  ref.on(event, cb, console.log)
  return () => ref.off(event, cb)
}

export const useFirebaseImage = pathKey => {
  const ref = firebase.storage().ref()
  const [url, setUrl] = useState(null)

  useEffect(() => {
    const getDownloadURL = async () => {
      const downloadURL = await ref.child(pathKey).getDownloadURL()
      setUrl(downloadURL)
    }

    pathKey && getDownloadURL()
  }, [pathKey])

  return url
}

export const useSetFirebaseImage = (file, projectKey) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (file && projectKey) {
      const ref = firebase.storage().ref('images')
      ref.put(file).on(
        'state_changed',
        snap => {
          const isLoaded = snap.bytesTransferred >= snap.totalBytes
          setLoading(!isLoaded)
        },
        err => {
          if (err) {
            setError(err)
            setLoading(false)
          }
        },
        async () => {
          const downloadUrl = await ref.child(`${projectKey}`).getDownloadURL()
          setUrl(downloadUrl)
        }
      )
    }
  }, [file, projectKey])

  return { data: { url, filePath: projectKey ? `images/${projectKey}` : null }, loading, error }
}

export const useFirebaseQuery = (pathKey, options = {}) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    const unsubscribe = firebaseDatabseSubscribe({
      path: pathKey,
      callback: val => {
        queryClient.setQueryData(pathKey, val)
      }
    })

    return () => unsubscribe()
  }, [queryClient, pathKey])

  return useQuery(pathKey, () => new Promise(() => {}), options)
}
