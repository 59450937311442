import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import IdentityProvider from './context/Identity'
import HeaderProvider from './context/Header'
import SocialProvider from './context/Social'
import AuthRoute from './components/AuthRoute'
import DesktopContact from 'app/components/ContactModal'
import routes from './routes'
import './index.css'

const queryClient = new QueryClient()

const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <IdentityProvider>
          <HeaderProvider>
            <SocialProvider>
              <DesktopContact />
              <Switch>
                <Route
                  exact={routes.homePage.exact}
                  path={routes.homePage.path}
                  component={routes.homePage.component}
                />
                <Route
                  exact={routes.portfolio.exact}
                  path={routes.portfolio.path}
                  component={routes.portfolio.component}
                />
                <Route
                  exact={routes.social.exact}
                  path={routes.social.path}
                  component={routes.social.component}
                />
                <AuthRoute
                  exact={routes.admin.exact}
                  path={routes.admin.path}
                  component={routes.admin.component}
                />
                <Route path="/" render={() => <Redirect to="/" />} />
              </Switch>
            </SocialProvider>
          </HeaderProvider>
        </IdentityProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
