import React, { useState } from 'react'
import classNames from 'classnames'
import useReactRouter from 'use-react-router'
import { ReactComponent as Phone } from './phone.svg'
import styles from './styles.module.css'

const ContactTrigger = () => {
  const [isHovered, setIsHovered] = useState(false)
  const { history, location } = useReactRouter()
  const reroute = () => {
    history.push({ ...location, search: 'contact' })
    setIsHovered(false)
  }

  if (location.search !== '?contact') {
    return (
      <div
        className={styles.contact}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Phone
          className={classNames(styles.icon, { [styles.hovered]: isHovered })}
          onClick={reroute}
        />
        {isHovered && <div className={styles.tooltip}>Contact Me</div>}
      </div>
    )
  }

  return null
}

export default ContactTrigger
