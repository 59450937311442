import React from 'react'
import { Route } from 'react-router-dom'
import { useIdentityContext } from 'app/context/Identity'
import Auth from './Auth'

const AuthRoute = ({ component: Component, ...rest }) => {
  const contextValue = useIdentityContext()
  const { user } = contextValue

  return (
    <Route
      {...rest}
      render={() => {
        if (user) {
          return user.loggedIn && user.authorized ? <Component /> : <Auth {...contextValue} />
        }

        return null
      }}
    />
  )
}

export default AuthRoute
