import React from 'react'
import classNames from 'classnames'
import { useSocialContext } from 'app/context/Social'
import Post from '../Post'
import styles from './styles.module.css'

const Pinterest = () => {
  const {
    pinterest: { data }
  } = useSocialContext()
  return (
    <>
      <div className={styles.pinterestLinkContainer}>
        <a href="https://pin.it/4fpxlgU" target="_blank" rel="noreferrer">
          <div className={styles.pinterestLink}>
            <i className={classNames('fa fa-pinterest', styles.icon)} aria-hidden="true"></i>
            <span className={styles.profileName}>{'@ellierachelbaer'}</span>
          </div>
        </a>
      </div>
      <div className={styles.pinterestFeed}>
        {!!data?.feed?.length
          ? data.feed.map(pinterestPost => (
              <React.Fragment key={`pin-${pinterestPost.id}`}>
                <Post {...pinterestPost} />
              </React.Fragment>
            ))
          : null}
      </div>
    </>
  )
}

export default Pinterest
