import React from 'react'
import styles from './styles.module.css'

const TextArea = ({ value, placeholder, onChange, ...rest }) => {
  return (
    <div className={styles.textAreaContainer}>
      <textarea
        className={styles.textArea}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
    </div>
  )
}

export default TextArea
