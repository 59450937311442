import React from 'react'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'
import { useBreakpoint } from 'app/hooks'

const Header = ({ isDesktopHeaderOpen }) => {
  const isMobile = useBreakpoint('md')

  return isMobile ? <MobileHeader /> : <DesktopHeader isOpen={isDesktopHeaderOpen} />
}

export default Header
