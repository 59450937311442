import React, { useEffect, useState } from 'react'
import Modal, { ModalTrigger, ModalHeader, ModalContent, ModalFooter } from 'app/components/Modal'
import isEmpty from 'lodash/isEmpty'
import Button from 'app/components/Button'
import { TextField, Select, TextArea, FileUpload } from '../components'
import Frame from 'app/components/Frame'
import { firebase } from 'app/firebase'
import styles from './styles.module.css'
import { useFirebaseQuery, useSetFirebaseImage } from 'app/hooks'

const ProjectModal = ({ onClose, project, projectKey }) => {
  const [newProject, setNewProject] = useState({
    title: project?.title || '',
    description: project?.description || '',
    aspectRatio: project?.aspectRatio || ''
  })

  const [file, setFile] = useState(null)
  const [newProjectKey, setNewProjectKey] = useState(projectKey)
  const { data } = useSetFirebaseImage(file, projectKey)

  useEffect(() => {
    if (!newProjectKey) {
      const firebaseProjectRef = firebase.database().ref('projects')
      setNewProjectKey(firebaseProjectRef.push().key)
    }
  }, [projectKey])

  useEffect(() => {
    data.filePath && setNewProject({ ...newProject, image: data.filePath })
  }, [data.filePath])

  const canProjectBeSaved =
    newProject.title && newProject.description && newProject.aspectRatio && newProjectKey

  const onSaveProject = () => {
    if (!canProjectBeSaved) return

    firebase.database().ref(`projects/${newProjectKey}`).set({
      title: newProject.title,
      description: newProject.description,
      aspectRatio: newProject.aspectRatio,
      image: data.filePath
    })

    onClose()
  }

  const onArchiveProject = () => {
    if (!canProjectBeSaved) return

    firebase.database().ref(`projects/${newProjectKey}`).set({
      title: newProject.title,
      description: newProject.description,
      aspectRatio: newProject.aspectRatio,
      image: data.filePath,
      archived: true
    })

    onClose()
  }

  const createInputHandler = type => e => {
    e.preventDefault()
    setNewProject({ ...newProject, [type]: e.target.value })
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader onClose={onClose} title={project ? 'Edit Project' : 'New Project'} />
      <ModalContent>
        <form className={styles.projectForm}>
          <TextField
            onChange={createInputHandler('title')}
            value={newProject.title}
            placeholder="Project title"
          />
          <TextArea
            onChange={createInputHandler('description')}
            value={newProject.description}
            placeholder="Project description"
          />
          <div className={styles.selectors}>
            <FileUpload
              label="Image"
              imageData={data}
              onChange={e => {
                e.preventDefault()
                setFile(e.target.files[0])
              }}
            />
            <Select
              onChange={createInputHandler('aspectRatio')}
              inputValue={newProject.aspectRatio}
              label="Aspect ratio"
              options={[
                { label: '16:9', value: 'sixteenNine' },
                { label: '4:3', value: 'fourThree' },
                { label: '1:1', value: 'oneOne' }
              ]}
            />
          </div>
        </form>
        <div className={styles.projectPreview}>
          {canProjectBeSaved ? (
            <Frame
              image={data.filePath}
              aspectRatio={newProject.aspectRatio}
              title={newProject.title}
            />
          ) : (
            <div className={styles.blankProject}>
              Project preview will appear when you've filled out all fields
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        {canProjectBeSaved && (
          <Button onClick={onArchiveProject} size="medium" className={styles.footerButton}>
            Archive
          </Button>
        )}
        <Button
          onClick={onSaveProject}
          disabled={!canProjectBeSaved}
          size="medium"
          className={styles.footerButton}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const EditProjects = () => {
  const { data: projectData, loading, error } = useFirebaseQuery('projects/')
  const [projectModalProps, setProjectModalProps] = useState({ project: null, projectKey: null })
  const [isNewModalOpen, setIsNewModalOpen] = useState(false)
  return (
    <>
      {isNewModalOpen && (
        <ProjectModal
          onClose={() => {
            setIsNewModalOpen(false)
            setProjectModalProps({ project: null, projectKey: null })
          }}
          {...projectModalProps}
        />
      )}
      <div className={styles.pageContent}>
        <div className={styles.col}>
          {!isEmpty(projectData) &&
            Object.entries(projectData).map(([projectKey, project]) => {
              return (
                <div
                  onClick={() => {
                    setProjectModalProps({ projectKey, project })
                    setIsNewModalOpen(true)
                  }}
                  key={projectKey}
                >
                  <Frame {...project} interactive={false} />
                </div>
              )
            })}
        </div>
        <div className={styles.col}></div>
        <ModalTrigger onOpen={() => setIsNewModalOpen(true)} />
      </div>
    </>
  )
}

export default EditProjects
