import React, { useState } from 'react'
import classNames from 'classnames'
import useReactRouter from 'use-react-router'
import { useHeaderContext } from 'app/context/Header'
import styles from './styles.module.css'

const Footer = () => {
  const { history, location } = useReactRouter()
  const { onCloseHeader } = useHeaderContext()
  const [isFooterClosed, setIsFooterClosed] = useState(false)

  const onAdminClick = () => {
    setIsFooterClosed(true)
    onCloseHeader()
    setTimeout(() => {
      history.push({ ...location, pathname: 'admin' })
    }, 600)
  }

  return (
    <div className={classNames(styles.footer, { [styles.closed]: isFooterClosed })}>
      {!isFooterClosed && (
        <>
          <span className={styles.copyright}>© 2021 Ellie Baer Design</span>
          <span className={styles.author}>
            Website by{' '}
            <a href="https://www.linkedin.com/in/timharding31/" target="_blank" rel="noreferrer">
              Tim Harding
            </a>
          </span>
          <span className={styles.link} onClick={onAdminClick}>
            Admin Portal
          </span>
        </>
      )}
    </div>
  )
}

export default Footer
