import React from 'react'
import styles from './styles.module.css'

const FileUpload = ({ imageData, onChange, label, ...rest }) => {
  return (
    <div className={styles.inputContainer} {...rest}>
      <span className={styles.label}>{label}</span>
      <label className={imageData.url ? styles.fileName : styles.button}>
        {imageData.url ? imageData.filePath : 'Upload from computer'}
        <input type="file" onChange={onChange} />
      </label>
    </div>
  )
}

export default FileUpload
