import React, { useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { useHeaderContext } from 'app/context/Header'
import { logout } from 'app/firebase'
import EditAbout from './EditAbout'
import EditTestimonials from './EditTestimonials'
import EditProjects from './EditProjects'
import AdminFlyout from './AdminFlyout'
import styles from './styles.module.css'

const contentOptions = [
  { title: 'Portfolio', slug: 'portfolio', component: EditProjects },
  { title: 'Client Testimonials', slug: 'testimonials', component: EditTestimonials },
  { title: 'About Me', slug: 'about', component: EditAbout }
]

const HashRouter = ({ selection }) => {
  const { component: Component } = contentOptions?.find(({ slug }) => selection === `#${slug}`)
  return <Component />
}

const Admin = () => {
  const { history, location } = useReactRouter()

  const [selection, setSelection] = useState(location?.hash || '#portfolio')
  const [isAdminFlyoutOpen, setIsAdminFlyoutOpen] = useState(true)

  const { onCloseHeader, onOpenHeader } = useHeaderContext()
  useEffect(onCloseHeader, [])

  const selectOption = option => {
    history.replace({ ...location, hash: option.slug })
    setSelection(`#${option.slug}`)
  }

  const onBack = () => {
    setIsAdminFlyoutOpen(false)
    setTimeout(() => {
      const pathnameIfSelected = location?.hash?.replace('#', '')
      history.push({ ...location, pathname: pathnameIfSelected || '/', hash: '' })
    }, 500)
  }

  return (
    <>
      <div>Admin Home</div>

      <div className={styles.page}>
        <AdminFlyout
          onSelectOption={selectOption}
          contentOptions={contentOptions}
          onBack={onBack}
          onLogout={logout}
          selection={selection}
          isOpen={isAdminFlyoutOpen}
        />
        <div className={styles.main}>
          <div className={styles.contentContainer}>
            <HashRouter selection={selection} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Admin
