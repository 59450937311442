import React from 'react'
import styles from './styles.module.css'

const TextField = ({ value, placeholder, onChange, ...rest }) => {
  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
    </div>
  )
}

export default TextField
