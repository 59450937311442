import React, { useState, useEffect } from 'react'
import PageContent from 'app/components/PageContent'
import Testimonials from './Testimonials'
import Frame from 'app/components/Frame'
import { useBreakpoint, useFirebaseQuery } from 'app/hooks'
import styles from './styles.module.css'

const RenderPortfolio = ({ numProjects, projectData, isMobile }) => {
  const sortedProjects = Object.entries(projectData).sort(
    ([_B, projectA], [_A, projectB]) => (projectA.order || 0) - (projectB.order || 0)
  )
  if (numProjects > 0) {
    if (isMobile) {
      return (
        <div className={styles.mobileOuter}>
          <div className={styles.col}>
            {sortedProjects.map(([projectKey, project]) => (
              <Frame isMobile {...project} key={projectKey} />
            ))}
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.outer}>
          <div className={styles.col}>
            {sortedProjects.slice(0, Math.floor(numProjects / 2)).map(([projectKey, project]) => (
              <Frame {...project} key={projectKey} />
            ))}
          </div>
          <div className={styles.col}>
            {sortedProjects
              .slice(Math.floor(numProjects / 2), numProjects)
              .map(([projectKey, project]) => (
                <Frame {...project} key={projectKey} />
              ))}
          </div>
        </div>
      )
    }
  }

  return null
}

const Portfolio = () => {
  const { data } = useFirebaseQuery('projects')
  const isMobile = useBreakpoint('md')

  const numProjects = data ? Object.keys(data).length : 0

  return (
    <PageContent>
      <Testimonials />
      {data && <RenderPortfolio numProjects={numProjects} projectData={data} isMobile={isMobile} />}
    </PageContent>
  )
}

export default Portfolio
