import { createContext, useState, useEffect, useContext } from 'react'

const defaultSocial = {
  instagram: {
    data: undefined,
    loading: true,
    error: false
  },
  pinterest: {
    data: undefined,
    loading: true,
    error: false
  }
}

const SocialContext = createContext(defaultSocial)

const SocialContextProvider = ({ children }) => {
  const [instagram, setInstagram] = useState(defaultSocial['instagram'])
  const [pinterest, setPinterest] = useState(defaultSocial['pinterest'])

  useEffect(() => {
    const fetchInstagram = async () => {
      const url = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url&&access_token=${process.env.REACT_APP_IG_TOKEN}&&limit=500`
      const response = await fetch(url)
      const json = await response.json()

      if (json.hasOwnProperty('error')) {
        setInstagram({ ...instagram, error: true, loading: false })
      } else if (!!json?.data?.length) {
        setInstagram({
          ...instagram,
          data: { feed: json.data, paging: json.paging },
          loading: false,
          error: false
        })
      }
    }

    const fetchPinterest = async () => {
      const url = 'https://api.pinterest.com/v3/pidgets/boards/474989160638474427/pins/'
      const response = await fetch(url)
      const json = await response.json()

      if (json.hasOwnProperty('error')) {
        setPinterest({ ...pinterest, error: true, loading: false })
      } else if (!!json?.data?.pins?.length) {
        setPinterest({
          ...pinterest,
          loading: false,
          data: {
            feed: json.data.pins.map(pin => {
              if (pin.id && pin.images['564x'].url) {
                return {
                  id: pin.id,
                  media_url: pin.images['564x'].url,
                  permalink: `https://www.pinterest.com/pin/${pin.id}/`
                }
              }
            })
          }
        })
      }
    }

    fetchInstagram()
    fetchPinterest()

    return () => {
      setInstagram(defaultSocial['instagram'])
      setPinterest(defaultSocial['pinterest'])
    }
  }, [])

  return (
    <SocialContext.Provider value={{ instagram, pinterest }}>{children}</SocialContext.Provider>
  )
}

const useSocialContext = () => {
  const socialContext = useContext(SocialContext)

  return socialContext
}

export default SocialContextProvider

export { SocialContext, useSocialContext }
