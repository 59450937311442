import React from 'react'
import classNames from 'classnames'
import { useSocialContext } from 'app/context/Social'
import Post from '../Post'
import styles from './styles.module.css'

const Instagram = () => {
  const {
    instagram: { data }
  } = useSocialContext()

  return (
    <>
      <div className={styles.instagramLinkContainer}>
        <a href="https://www.instagram.com/dept_oftheinterior/" target="_blank" rel="noreferrer">
          <div className={styles.instagramLink}>
            <i className={classNames('fa fa-instagram', styles.icon)} aria-hidden="true"></i>
            <span className={styles.profileName}>{'@dept_oftheinterior'}</span>
          </div>
        </a>
      </div>
      <div className={styles.instagramFeed}>
        {!!data?.feed?.length
          ? data.feed.map(instagramPost => (
              <React.Fragment key={`insta-${instagramPost.id}`}>
                <Post {...instagramPost} />
              </React.Fragment>
            ))
          : null}
      </div>
    </>
  )
}

export default Instagram
