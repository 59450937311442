import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

const Button = ({ size, className, children, ...rest }) => {
  return (
    <button className={classNames(styles.button, styles[size], className)} {...rest}>
      <span>{children}</span>
    </button>
  )
}

export default Button
