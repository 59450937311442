import React from 'react'
import classNames from 'classnames'
import { useIdentityContext } from 'app/context/Identity'
import Flyout from 'app/components/Flyout'
import Button from 'app/components/Button'
import styles from './styles.module.css'

const AdminFlyout = ({ isOpen, onLogout, onBack, contentOptions, onSelectOption, selection }) => {
  const {
    user: { displayName, photoUrl }
  } = useIdentityContext()

  return (
    <Flyout isOpen={isOpen} title="Admin Portal">
      <div className={styles.userInfo}>
        {photoUrl && <img className={styles.avatar} src={photoUrl} />}
        <span className={styles.displayName}>{displayName}</span>
      </div>
      <div className={styles.contentSelector}>
        {contentOptions.map(option => (
          <span
            key={`content-option-${option.slug}`}
            className={classNames(styles.contentOption, {
              [styles.active]: selection === `#${option.slug}`
            })}
            onClick={() => onSelectOption(option)}
          >
            {option.title}
          </span>
        ))}
      </div>
      <div className={styles.authActions}>
        <Button className={styles.authAction} size="medium" onClick={onBack}>
          back to site
        </Button>
        <Button className={styles.authAction} onClick={onLogout} size="medium">
          sign out
        </Button>
      </div>
    </Flyout>
  )
}

export default AdminFlyout
