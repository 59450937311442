import React, { useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { useBreakpoint } from 'app/hooks'
import styles from './styles.module.css'

const NavLink = ({ slug, title, size }) => {
  const { history, location } = useReactRouter()
  const isActive = `/${slug || ''}` === location.pathname
  const isMediumSized = useBreakpoint('lg')
  const isSmall = useBreakpoint('ml')

  return (
    <div
      className={classNames(styles.nav, styles[size], {
        [styles.active]: isActive,
        [styles.needsSmallerFont]: isMediumSized,
        [styles.needsEvenSmallerFont]: isSmall
      })}
    >
      <span onClick={() => history.replace({ ...location, pathname: slug || '/' })}>{title}</span>
    </div>
  )
}

const DesktopHeader = ({ isOpen }) => {
  const [animateClose, setAnimateClose] = useState(false)

  useEffect(() => {
    if (isOpen && !animateClose) {
      setAnimateClose(true)
    }

    if (!isOpen && animateClose) {
      setAnimateClose(false)
    }
  }, [isOpen, animateClose])

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.header, {
        [styles.open]: isOpen,
        [styles.animateClose]: animateClose
      })}
    >
      <NavLink slug="portfolio" title="Portfolio" />
      <NavLink title="Ellie Baer" size="large" />
      <NavLink slug="social" title="Social" />
    </div>,
    document.body
  )
}

export default DesktopHeader
