import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import useReactRouter from 'use-react-router'
import Flyout from 'app/components/Flyout'
import Button from 'app/components/Button'
import { startFirebaseAuth, logout } from 'app/firebase'
import styles from './styles.module.css'
import { useHeaderContext } from 'app/context/Header'

const Auth = ({ user }) => {
  const { history, location } = useReactRouter()
  const [isAuthFlyoutOpen, setIsAuthFlyoutOpen] = useState(true)
  const [errors, setErrors] = useState([])
  const { isHeaderOpen, onCloseHeader } = useHeaderContext()

  useEffect(() => {
    startFirebaseAuth('#firebase-auth')
    isHeaderOpen && onCloseHeader()
  }, [])

  useEffect(() => {
    if (user?.loggedIn && !user?.authorized) {
      setErrors(["You're authorized to view this page"])
      logout()
      startFirebaseAuth('#firebase-auth')
    }
  }, [user])

  const onBack = () => {
    setIsAuthFlyoutOpen(false)
    setTimeout(() => {
      setErrors([])
      history.push({ ...location, pathname: '/', hash: '' })
    }, 500)
  }

  if (user) {
    return (
      <>
        <Flyout isOpen={isAuthFlyoutOpen} title="Admin Portal">
          <div className={classNames(styles.authMessages, { [styles.error]: errors.length > 0 })}>
            {errors.length === 0 ? (
              <span>You must sign in before proceeding</span>
            ) : (
              <>
                {errors.map((errorMsg, idx) => (
                  <span key={`error-${idx}`}>{errorMsg}</span>
                ))}
              </>
            )}
          </div>

          <div id="firebase-auth" className={styles.firebaseAuth} />
          <div className={styles.authActions}>
            <Button className={styles.authAction} variant="blue" onClick={onBack}>
              back to site
            </Button>
          </div>
        </Flyout>
      </>
    )
  }

  return null
}

export default Auth
