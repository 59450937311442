import React, { useEffect } from 'react'
import Footer from './Footer'
import ContactTrigger from './ContactTrigger'
import { useBreakpoint } from 'app/hooks'
import { useHeaderContext } from 'app/context/Header'
import styles from './styles.module.css'

const Page = ({ children }) => {
  const isMobile = useBreakpoint('md')
  const { isHeaderOpen, onOpenHeader } = useHeaderContext()

  useEffect(() => {
    if (!isHeaderOpen && !isMobile) {
      onOpenHeader()
    }
  }, [])

  return (
    <>
      <div className={styles.page}>
        <div className={styles.content}>{children}</div>
        {!isMobile && <Footer />}
      </div>
      {!isMobile && <ContactTrigger />}
    </>
  )
}

export default Page
