import React from 'react'
import styles from './styles.module.css'

const Select = ({ options, inputValue, onChange, label, ...rest }) => {
  return (
    <div className={styles.selectContainer}>
      <span className={styles.label}>{label}</span>
      <select onChange={onChange} value={inputValue} className={styles.select} {...rest}>
        <option value="">--</option>
        {options.map(({ value, label }, idx) => (
          <option value={value} key={`select-${value}-${idx}`}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
